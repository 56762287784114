<template>
  <div class="random-group-wrapper" v-loading="initLoading">
    <div v-if="prevTaskStatus === '10'" class="empty-wrapper">
      <img src="@/assets/image/no-image.png" width="120px"/>
      {{ isRandomResultPage ? '请提交入排问卷然后进行随机化，即可查看随机结果。' : '请先提交入排问卷后再进行随机化。'}}
    </div>
    <div v-else-if="isRandomResultPage && !finishRandomForm" class="empty-wrapper">
      <img src="@/assets/image/no-image.png" width="120px"/>
      请提交入排问卷然后进行随机化，即可查看随机结果。
    </div>
    <div v-else-if="!isCanJoin" class="empty-wrapper">
      <img src="@/assets/image/no-image.png" width="120px"/>
        入排问卷不符合入组标准，无法进行随机化分组。
    </div>
    <template v-else>
      <div v-if="isRandomResultPage" class="empty-wrapper">
        <i class="el-icon-circle-check"></i>
        <p class="result-tips">随机分组成功</p>
        <p class="result-tips bold">随机日期为：{{ join_date }}</p>
        <p class="result-tips">据分期和病理亚型进行分层随机，标准治疗组:MRD指导组比例为1:2</p>
        <p class="result-tips">该受试者被分配到</p>
        <p class="result-tips bigger">{{ group_type_name }}</p>
      </div>
      <template v-else>
        <iframe
          ref="iframe"
          :src="questionaireUrl"
          frameBorder="no"
          border="0"
          marginWidth="0"
          marginHeight="0"
          style="width: 100%;height: 100%;background: #fff"
        />
      </template>
    </template>
  </div>
</template>
<script>
import {
  apiModifyTask,
  apiGetTaskQuestionnaire,
  apiGetTaskDetail,
  apiGetGroupResult
} from '@/apis/NSCLC/modalQuestionaire'

import store from '@/store'
import randomGroup from './config/randomGroup'
import dayjs from 'dayjs'

export default {
  props: {
    taskCode: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      default: ''
    },
    taskStatus: {
      type: String,
      default: ''
    },
    tasks: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      questionaireForm: {},
      loading: false,
      disableEdit: false,
      isCanJoin: false, // 是否符合入组条件
      group_type_name: '',
      initLoading: false,
      join_date: '',
      version: '',
      questionaireUrl: '',
      questionnaireInfo: {}
    }
  },
  computed: {
    questionnaireConfig() {
      return randomGroup && randomGroup.config
    },
    apiType() {
      return randomGroup && randomGroup.type
    },
    enrollForm() {
      return this.tasks.find(item => item.task_code === 'CTTK00020110')
    },
    prevTaskStatus() {
      // 入组问卷的状态
      const item = this.tasks.find(item => item.task_code === 'CTTK00020110')
      return item && item.task_status
    },
    isRandomResultPage() {
      // 随机化结果页面
      return this.taskCode === 'CTTK00020130'
    },
    disableNSCLCOperation() {
      return store.state.user.disableNSCLCOperation
    }
  },
  created() {
    if (this.taskCode && this.prevTaskStatus === '30') {
      this.checkCanJoinGroup()
    }
  },
  mounted() {
    window.addEventListener('message', this.postMessage)
  },
  watch: {
    taskCode(val) {
      if (val) {
        if (this.prevTaskStatus === '30') {
          this.checkCanJoinGroup()
        }
      }
    },
  },
  methods: {
    handleSortNo(index) {
      return index + 1 > 9 ? index + 1 : '0' + (index + 1)
    },
    async checkCanJoinGroup() {
      this.initLoading = true
      if (this.isRandomResultPage) {
        const item = this.tasks.find(item => item.task_code === 'CTTK00020120')
        this.finishRandomForm = item.task_status === '30'
      }
      this.getResult()
    },
    buildQUrl(questionnaireInfo, stepStatus) {
      let base_url = ''
      // 开发默认使用本地，可切换
      if (process.env.NODE_ENV === 'development') {
        // 本地问卷，需要确认其对应的后端
        base_url = 'http://localhost:3000'
        // 测试问卷
        // base_url = 'http://172.16.18.169'
      }
      this.questionaireUrl = `${base_url}/selfDeveloped/questionaire?code=${questionnaireInfo.questionnaire_code}&resultId=${questionnaireInfo.result_id}&defaultVersion=${questionnaireInfo.version}&hasNext=0&isPC=1`
      this.showIframe = true
    },
    async getResult() {
      const params = {
        patient_id: this.patientId,
        task_code: this.taskCode
      }
      const res = await apiGetGroupResult(params)
      if (res.code === 200) {
        this.group_type_name = res.entity.group_type_name
        this.join_date = res.entity.join_datetime ? dayjs(res.entity.join_datetime).format('YYYY-MM-DD') : ''
        this.isCanJoin = res.entity.can_enroll
        // 入组成功 随机化问卷展示
        if (this.isCanJoin) {
          const params = {
            patient_id: this.patientId,
            task_code: this.taskCode
          }
          const res = await apiGetTaskQuestionnaire(params)
          if (res.code === 200) {
            this.version = res.entity.version
            this.questionnaireInfo = res.entity
            this.buildQUrl(this.questionnaireInfo)
          }
        } 
      } else {
        this.$message.error(res.message)
      }
      this.initLoading = false
    },
    // 接收问卷传来的信息
    postMessage(e) {
      if (e.data === 'closeOne') {
        this.closeDialog()
      } else if (e.data === 'closeModal') {
        this.forceReloadIframe()
        this.refreshNavigator()
        if (document.getElementsByClassName('el-message').length >= 1) {
          return
        }
        this.$message.success('提交成功')
      } else if (e.data === 'getToken') {
        setTimeout(() => {
          this.sendMsg()
        }, 500)
      } else if (e.data === 'nextStep') {
        this.closeDialog()
        this.$emit('nextStep')
      } else if (e.data.action === 'toggleVersion') {
        const questionnaireInfo = {
          questionnaire_code: e.data.questionaire_code,
          result_id: e.data.result_id,
          version: e.data.version
        }
        this.buildQUrl(questionnaireInfo)
      }
    },
    // 发消息给问卷
    sendMsg() {
      const iframe = this.$refs.iframe
      if (iframe) {
        iframe.contentWindow.postMessage({
          accessToken: localStorage.getItem('accessToken'),
          prefixApi: store.state.currentProject || this.prefix,
          bodyParams: {
            disabledEdit: store.state.user.disableNSCLCOperation,
            disabledFutureDate: true, // 禁止未来日期选择
            patientId: this.patientId,
            businessCode: this.taskCode
          }
        }, '*')
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('message', this.postMessage)
  }
}
</script>
<style lang="scss" scoped>
.random-group-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .random-tips {
    padding: 16px;
  }
  .el-icon-circle-check {
    font-size: 60px;
    color: #50b240;
    margin-bottom: 10px;
  }
  .result-tips {
    font-size: 14px;
    margin-top: 0;
    font-weight: 500;
    &.bold {
      color: #000;
    }
    &.bigger {
      font-size: 30px;
      color: #000;
    }
  }
}
.custom-submit-btn {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-60px);
  width: 120px;
}
.empty-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.enroll-form-title {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  .enroll-form-version {
    font-size: 12px;
    font-weight: normal;
    float: right;
  }
  .enroll-form-status {
    font-size: 12px;
    margin-left: 10px;
    font-weight: normal;
    float: right;
  }
}
</style>