<template>
  <div>
    <el-dialog
      title="变更负责人"
      :visible="dialogVisible"
      class="genetrials-common-dialog modal-change-charge"
      width="700px"
      @close="closeDialog">
      <div class="content-section">
        <h4>将以下项目中：</h4>
        <div class="checkbox-group-wrapper">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >
            全选
          </el-checkbox>
          <el-checkbox-group
            v-model="selectedProjects"
            @change="handleSelectedProjectsChange"
          >
            <el-checkbox
              v-for="project in projectList"
              :key="project.project_code"
              :label="project.project_code"
            >
              {{ project.project_name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="content-section">
        <h4>受试者的负责人为：</h4>
        <el-radio-group v-model="originalResponsible">
          <el-radio
            v-for="person in users"
            :key="person.user_id"
            :label="person.user_id"
          >
            {{ person.display_name }}
          </el-radio>
        </el-radio-group>
      </div>

      <div class="content-section">
        <h4>负责人变更为：</h4>
        <el-radio-group v-model="newResponsible">
          <el-radio
            v-for="manager in users"
            :key="manager.user_id"
            :label="manager.user_id"
          >
            {{ manager.display_name }}
          </el-radio>
        </el-radio-group>
      </div>
      <p class="content-tips">变更负责人后，短信邮件等消息通知，都以新的负责人为准。</p>
      <p class="content-tips">数据日志中，依然可以查到原来添加受试者的人。</p>
      <p class="content-tips warning">此操作影响数据范围广泛，请谨慎操作。</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmChange">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiPostModifyPrincipal } from '@/apis/system';
import { notifyError, notifySuccess } from '../../helpers/message'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    projectList: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedProjects: [],
      originalResponsible: null,
      newResponsible: null,
      isIndeterminate: false,
      checkAll: false,
    };
  },
  methods: {
    async confirmChange() {
      // console.log('选择结果:', {
      //   projects: this.selectedProjects,
      //   original: this.originalResponsible,
      //   new: this.newResponsible,
      // });
      const params = {
        project_code: this.selectedProjects.join(','),
        original_principal_id: this.originalResponsible,
        target_principal_id: this.newResponsible
      }
      const res = await apiPostModifyPrincipal(params)
      if (res.code === 200) {
        notifySuccess('操作成功')
        this.closeDialog()
      } else {
        notifyError(res.message)
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    handleCheckAllChange(val) {
      this.selectedProjects = val ? this.projectList.map(project => project.project_code) : [];
      this.isIndeterminate = false;
    },
    handleSelectedProjectsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.projectList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.projectList.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-section {
  margin-bottom: 20px;
}
h4 {
  margin: 10px 0;
}
::v-deep .el-checkbox {
  margin: 5px;
}
::v-deep .el-radio {
  width: 25%;
  margin: 3px 0;
}
.content-tips {
  margin: 0;
  &.warning {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
