<template>
  <el-dialog
    v-if="showModal"
    title="切换CRF版本"
    :visible="showModal"
    width="300px"
    :modal="false"
    append-to-body
    class="genetrials-common-dialog modal-version"
    @close="closeModal"
  >
    <div v-loading="loading">
      <ul class="version-list">
        <li
          v-for="item in versionList"
          :key="item.version"
          :class="['version-item', item.version === currentVersion ? 'active' : '']"
          @click="changeVersion(item.version)"
        >
          <div class="version-info">{{ item.version }}</div>
          <div class="version-status">{{ item.status === 30 ? '已提交' : '未提交' }}</div>
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeModal" class="btn-close">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getQustionaireVersions, toggleVersion } from '@/apis/questionnaire.js'

export default {
  name: 'ModalVersion',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    currentVersion: {
      type: String,
      default: ''
    },
    patientId: {
      type: String,
      required: true
    },
    taskCode: {
      type: String,
      required: true
    },
    questionaireCode: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      versionList: [],
      loading: false
    }
  },
  watch: { 
    showModal: {
      handler(newVal) {
        if (newVal) {
          this.getVersionList()
        }
      },
      immediate: true
    }
  },
  methods: {
    async getVersionList() {
      this.loading = true
      try {
        const params = {
          patientId: this.patientId,
          businessCode: this.taskCode,
          questionnaire_code: this.questionaireCode,
        }
        const versionsRes = await getQustionaireVersions(params)
        if (versionsRes.code === 200) {
          this.versionList = versionsRes.entities
        }
      } finally {
        this.loading = false
      }
    },
    async changeVersion(version) {
      const params = {
        "task_code": this.taskCode,
        "version": version,
        "patient_id": this.patientId,
        "questionnaire_code": this.questionaireCode,
      }
      const res = await toggleVersion(params)
      if (res.code === 200) {
        this.$emit('changeVersion', version, res.entity.result_id)
        this.$emit('closeModal')
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.version-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .version-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #e8e8e8;
    cursor: pointer;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.7);

    &.active {
      border-color: #1677ff;
      color: #1677ff;
    }
  }
}
</style>
<style lang="scss">
.genetrials-common-dialog.modal-version {
  margin-left: 140px;
  .el-dialog__header {
    padding: 6px 20px;
    .el-dialog__headerbtn {
      font-size: 20px;
      top: 5px;
      right: 22px;
    }
  }
  .el-dialog__body {
    padding: 20px 30px 30px;
    height: 100%;
  }
  .el-dialog__footer {
    padding: 15px;
  }
  .btn-close {
    margin-right: 0;
    width: 120px;
    border-radius: 2px;
    font-weight: 400;
  }
}
</style>