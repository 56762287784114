<template>
  <div>
    <el-dialog
      v-if="dialogVisible"
      title="受试者CRF"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog modal-common-questionaire"
      width="95vw"
      append-to-body
      @close="closeDialog"
    >
      <div class="modal-main">
        <div class="navigator-level1">
          <div class="patient-info">
            <p>{{ info.external_patient_code }}</p>
            <p>{{ info.name }}</p>
          </div>
          <div v-for="key in Object.keys(info.stepDetails)"
            :key="key"
            :class="['navigator-item',
              key === currentLevel1 ? 'active' : ''
            ]"
            @click="showNavigator(key)"
          >
          <el-tooltip class="item" effect="dark"
            :content="info.stepDetails[key].step_status === '30' ? '已完成' :
            info.stepDetails[key].step_status === '20' ? '进行中' : '未开始'"
            :open-delay="200"
            placement="right-start">
            <div>
              <img v-if="info.stepDetails[key].step_status === '30'" class="icon-submit" src="@/assets/image/ok.svg" />
              <span v-else-if="info.stepDetails[key].step_status === '20'" class="circle-flag green"></span>
              <span v-else class="circle-flag"></span>
              {{ info.stepDetails[key].step_display_name }}
            </div>
          </el-tooltip>
          </div>
        </div>
        <div class="navigator-level2">
          <div v-for="item in tasks"
            :key="item.task_code"
            :class="{'navigator-item': true, 'active': item.task_code === currentTaskCode}"
            @click="goFormMain(item)">
            <el-tooltip class="item" effect="dark"
              :content="item.task_status === '30' ? '已提交' : '未提交'"
              :open-delay="200"
              placement="right-start">
              <div>
                <img v-if="item.task_status === '30'" class="icon-submit" src="@/assets/image/ok.svg" />
                <span v-else class="circle-flag"></span>
                {{ item.task_display_name }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <span class="go-target-btn prev" :class="{'disabled': prevDisabled}" @click="goPrev()">
          <i class="el-icon-arrow-left"></i>
          上一页
        </span>
        <span class="go-target-btn next" :class="{'disabled': nextDisabled}" @click="goNext()">
          下一页
          <i class="el-icon-arrow-right"></i>
        </span>
        <div class="q-form-main">
          <iframe
            v-if="targetTaskType === '10' && questionaireUrl && showIframe"
            ref="iframe"
            :key="dynamicComponentKey"
            :src="questionaireUrl"
            frameBorder="no"
            border="0"
            marginWidth="0"
            marginHeight="0"
            style="width: 100%;height: 100%;background: #fff"
          />
          <component
            v-if="targetTaskType === '20' && visibleComponent"
            :is="visibleComponent"
            :dynamicComponentKey="dynamicComponentKey"
            :taskCode="currentTaskCode"
            :patientId="currentPatientId"
            :taskStatus="currentLevel2Item.task_status"
            :taskDisplayName="currentLevel2Item.task_display_name"
            :tasks="tasks"
            @goNext="refreshNavigator"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import FormIndex from './customForm/FormIndex.vue'
import RandomGroup from './customForm/RandomGroup.vue'
import EnrollForm from './customForm/EnrollForm.vue'
import store from '@/store'
import {
  apiTaskNavigation,
  apiGetTaskQuestionnaire
} from '@/apis/NSCLC/modalQuestionaire.js'

export default {
  props: {
    dialogVisible: {
      default: false,
      type: Boolean
    },
    currentPatientId: {
      default: '',
      type: String
    },
    targetStep: {
      default: '',
      type: String
    },
    targetTask: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      info: {
        name: '',
        external_patient_code: '',
        stepDetails: {}
      },
      stepList: [],
      currentLevel1: '',
      currentLevel2Item: '',
      targetTaskType: '',
      questionaireUrl: '',
      dynamicComponentKey: 1,
      showIframe: false,
    }
  },
  components: { FormIndex, RandomGroup, EnrollForm },
  created() {
    this.getNavigation()
  },
  watch: {
    currentPatientId() {
      this.getNavigation()
    }
  },
  computed: {
    tasks() {
      return this.currentLevel1 && this.info.stepDetails[this.currentLevel1].task_details
    },
    currentTaskCode() {
      return this.currentLevel2Item.task_code
    },
    visibleComponent() { // 显示的组件
      const map = {
        'CTTK00020120': 'random-group',
        'CTTK00020130': 'random-group',
        'CTTK00020110': 'enroll-form'
      }
      return map[this.currentTaskCode] || 'form-index'
    },
  },
  mounted() {
    window.addEventListener('message', this.postMessage)
  },
  methods: {
    async getNavigation(needRefresh) {
      const res = await apiTaskNavigation({
        patient_id: this.currentPatientId
      })
      if (res.code === 200) {
        const stepDetails = {}
        res.entity.step_details.map(item => {
          stepDetails[item.step_code] = item
        })
        this.stepList = res.entity.step_details
        this.info.name = res.entity.name
        this.info.external_patient_code = res.entity.external_patient_code
        this.info.stepDetails = stepDetails
        if (!needRefresh) {
          // 默认展示第一个step
          this.currentLevel1 = this.targetStep || res.entity.step_details[0].step_code
          this.currentLevel2Item = this.targetTask || this.info.stepDetails[this.currentLevel1].task_details[0]
          this.goFormMain(this.currentLevel2Item)
        } else {
          // 问卷最后一个步骤提交后不跳下一个一级
          this.goNext(true)
        }
      }
    },
    showNavigator(step_code) {
      this.currentLevel1 = step_code
      // 修改一级默认跳转到二级第一个
      this.currentLevel2Item = this.info.stepDetails[this.currentLevel1].task_details[0]
      this.goFormMain(this.currentLevel2Item)
    },
    async goFormMain(item) {
      this.targetTaskType = item.task_type
      this.currentLevel2Item = item
      this.dynamicComponentKey += 1
      if (this.targetTaskType === '10') {
        const res = await apiGetTaskQuestionnaire({
          patient_id: this.currentPatientId,
          task_code: item.task_code
        })
        if (res.code === 200) {
          this.buildQUrl(res.entity, item.task_status)
        }
      }
    },
    buildQUrl(questionnaireInfo, stepStatus) {
      let base_url = ''
      // 开发默认使用本地，可切换
      if (process.env.NODE_ENV === 'development') {
        // 本地问卷，需要确认其对应的后端
        base_url = 'http://localhost:3000'
        // 测试问卷
        // base_url = 'http://172.16.18.169'
      }
      this.questionaireUrl = `${base_url}/selfDeveloped/questionaire?code=${questionnaireInfo.questionnaire_code}&resultId=${questionnaireInfo.result_id}&defaultVersion=${questionnaireInfo.version}&hasNext=0&isPC=1`
      this.showIframe = true
    },
    closeDialog() {
      this.$emit('closeDialog')
      window.removeEventListener('message', this.postMessage)
    },
    forceReloadIframe() {
      this.showIframe = false; // 先移除 iframe
      this.$nextTick(() => {
        this.dynamicComponentKey++; // 增加 key 值
        this.showIframe = true; // 重新显示 iframe
      });
    },
    // 接收问卷传来的信息
    postMessage(e) {
      if (e.data === 'closeOne') {
        this.closeDialog()
      } else if (e.data === 'closeModal') {
        this.forceReloadIframe()
        this.refreshNavigator()
        if (document.getElementsByClassName('el-message').length >= 1) {
          return
        }
        this.$message.success('提交成功')
      } else if (e.data === 'getToken') {
        setTimeout(() => {
          this.sendMsg()
        }, 500)
      } else if (e.data === 'nextStep') {
        this.closeDialog()
        this.$emit('nextStep')
      } else if (e.data.action === 'toggleVersion') {
        const questionnaireInfo = {
          questionnaire_code: e.data.questionaire_code,
          result_id: e.data.result_id,
          version: e.data.version
        }
        this.buildQUrl(questionnaireInfo)
      }
    },
    // 发消息给问卷
    sendMsg() {
      const iframe = this.$refs.iframe
      if (iframe) {
        iframe.contentWindow.postMessage({
          accessToken: localStorage.getItem('accessToken'),
          prefixApi: store.state.currentProject || this.prefix,
          bodyParams: {
            disabledEdit: store.state.user.disableNSCLCOperation,
            disabledFutureDate: true, // 禁止未来日期选择
            patientId: this.currentPatientId,
            businessCode: this.currentLevel2Item.task_code
          }
        }, '*')
      }
    },
    goPrev() {
      const targetIdx = this.tasks.findIndex(task => {
        return task.task_code === this.currentTaskCode
      })

      // 如果是二级导航的第一个，则点击上一页跳转到上一个一级导航的最后一个。
      if (targetIdx === 0) {
        const level1Index = this.stepList.findIndex(step => step.step_code === this.currentLevel1)
        if (level1Index === 0) {
          this.$message.info('没有上一页了')
        } else {
          this.currentLevel1 = this.stepList[level1Index - 1].step_code
          const taskLength = this.info.stepDetails[this.currentLevel1].task_details.length
          this.currentLevel2Item = this.info.stepDetails[this.currentLevel1].task_details[taskLength - 1]
        }
      } else {
        this.currentLevel2Item = this.tasks[targetIdx - 1]
      }
      this.goFormMain(this.currentLevel2Item)
    },
    goNext(noJumpLevel1) {
      // 如果是该二级导航最后一个，则点击下一页跳转到下一个一级导航的第一步
      const targetIdx = this.tasks.findIndex(task => {
        return task.task_code === this.currentTaskCode
      })
      const taskLength = this.tasks.length
      if (targetIdx === taskLength - 1) {
        if (noJumpLevel1) {
          this.$nextTick(() => {
            this.questionaireUrl = ''
            this.goFormMain(this.currentLevel2Item)
          })
          return
        }
        const level1Index = this.stepList.findIndex(step => step.step_code === this.currentLevel1)
        if (level1Index === this.stepList.length - 1) {
          this.$message.info('没有下一页了')
        } else {
          this.currentLevel1 = this.stepList[level1Index + 1].step_code
          this.currentLevel2Item = this.info.stepDetails[this.currentLevel1].task_details[0]
        }
      } else {
        this.currentLevel2Item = this.tasks[targetIdx + 1]
      }
      this.goFormMain(this.currentLevel2Item)
    },
    refreshNavigator() {
      // 刷新导航并且进入下一步
      this.getNavigation(true)
    }
  }
}
</script>
<style lang="scss">
.genetrials-common-dialog.modal-common-questionaire {
  .el-dialog__body {
    padding: 0;
    height: 90vh;
    max-height: 90vh;
    overflow: hidden;
  }
  .custom-form-footer {
    border-top: 1px solid #d4d4d4;
    text-align: center;
    padding: 10px 16px;
    position: absolute;
    flex-shrink: 0;
    height: 50px;
    width: 100%;
    bottom: 0;
    box-sizing: border-box;
    background: #fff;
  }
  .modal-main {
    height: 100%;
    background: #fff;
    display: flex;
    .patient-info {
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 10px;
      padding-left: 8px;
      p {
        margin: 0;
        font-weight: 500;
        color: #000;
      }
    }
    .navigator-level1 {
      background: #e9e9e9;
      width: 130px;
      padding: 10px 0;
      height: 100%;
      flex-shrink: 0;
      box-sizing: border-box;
      overflow: auto;
    }
    .navigator-level2 {
      background: #f9f9f9;
      width: 150px;
      padding: 53px 0;
      height: 100%;
      flex-shrink: 0;
    }
    .q-form-main {
      flex: 1;
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    .navigator-item {
      cursor: pointer;
      padding-left: 10px;
      .el-tooltip {
        height: 28px;
        display: flex;
        align-items: center;
      }
      &:hover {
        background: #d9d9d9;
      }
      &.active {
        background: #c9c9c9;
      }
      .icon-submit {
        margin-right: 3px;
        width: 10px;
      }
      .circle-flag {
        align-items: center;
        border: 1px solid #333;
        border-radius: 50%;
        display: flex;
        height: 8px;
        justify-content: center;
        margin-right: 3px;
        width: 8px;
        &.green {
          border-color: #50b240;
        }
      }
    }
    .go-target-btn {
      color: #000;
      font-size: 14px;
      position: absolute;
      bottom: 16px;
      cursor: pointer;
      z-index: 11;
      &.prev {
        left: 306px;
      }
      &.next {
        right: 16px;
      }
      i {
        font-size: 18px;
      }
    }
  }
}
</style>