import { requestGet, requestPost } from "@/utils/request"
import store from '@/store'

// 获取问卷基础信息
export function getQuestionaireInfo(params, result_id, version) {
  return new Promise((resolve, reject) => {
    requestGet(store.state.currentPrefix + `/questionnaire/${params}?version=${version}&result_id=${result_id}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 暂存填写结果
export function saveResult(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/questionnaire/result/save', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 提交填写结果
export function submitResult(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/questionnaire/result/commit', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取问卷结果
export function getQuestionnareResult(params) {
  const { result_id, questionnaire_code, business_code, version } = params; // 解构赋值获取参数
  return new Promise((resolve, reject) => {
    requestGet(store.state.currentPrefix + `/questionnaire/result?result_ids=${result_id}&questionnaire_code=${questionnaire_code}&business_code=${business_code}&version=${version}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

//  获取问卷版本列表
export function getQustionaireVersions(params) {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/questionnaire/result/versions?patient_id=${params.patientId}&task_code=${params.businessCode}&questionnaire_code=${params.questionnaire_code}`).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

//  切换版本
export function toggleVersion(params) {
  const extraParams = {
    project_code: store.state.currentPrefix.substring(1),
    ...params
  }
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/questionnaire/result/switch-version`, extraParams).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}