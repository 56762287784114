import { requestGet, requestPost } from "@/utils/request"
import store from '@/store'

// 获取用户列表
export function getUserList(params) {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/user/list`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 添加用户
export function addUser(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/user/join`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 删除用户
export function removeUser(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/user/quit`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}

// 查找用户
export function findUser(params) {
  return new Promise((resolve, reject) => {
    requestPost(`${store.state.currentPrefix}/user/find`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}


// 机构列表
export function getInstitute(params) {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/institute?is_available=1`, params).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}


// 新增受试者
export function patientAdd(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/patient/add-patient', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 修改受试者信息
export function patientModify(params) {
  return new Promise((resolve, reject) => {
    requestPost(store.state.currentPrefix + '/patient/modify-patient', params).then(res => {
      resolve (res);
    },error => {
      reject(error)
    })
  })
}

// 获取CRC列表
export function getCRCList() {
  return new Promise((resolve, reject) => {
    requestGet(`${store.state.currentPrefix}/user/crc/list`, {}).then(res => {
      resolve(res);
    },error => {
      reject(error)
    })
  })
}