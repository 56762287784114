<template>
  <div>
    <el-dialog
      :title="title"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="genetrials-common-dialog modal-edit-user"
      width="700px"
      append-to-body
      @close="closeDialog"
    >
      <el-form v-loading="loading" ref="userForm" class="user-form" :rules="rules" :model="query" label-width="150px" size="small">
        <el-form-item label="用户姓名" prop="display_name">
          <el-input v-model="query.display_name" placeholder="请输入" clearable style="width: 320px"/>
        </el-form-item>
        <el-form-item label="登录手机号" prop="phone_number">
          <el-input v-model="query.phone_number" placeholder="请输入" clearable style="width: 320px"/>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="query.email" placeholder="请输入" clearable style="width: 320px"/>
        </el-form-item>
        <el-form-item label="所属单位" prop="department">
          <el-input v-model="query.department" placeholder="请输入" clearable style="width: 320px"/>
        </el-form-item>
        <el-form-item v-for="project in operateProjects"
          :key="project.project_code"
          :label="project.project_name">
          项目是否开通：<el-checkbox v-model="project.isSelected" label="开通"></el-checkbox>
          <div>
            项目角色：
            <el-radio-group v-model="project.role_ids" class="radio-group">
              <el-radio
                v-for="role in roleMap[project.project_code]"
                :label="role.role_id"
                :key="role.role_id">{{ role.role_name }}</el-radio>
            </el-radio-group>
          </div>
          <div>
            <p style="margin: 0">研究中心：</p>
            <el-tag v-for="(groupId, index) in project.groupIdList"
              :key="groupId"
              closable
              size="small"
              style="margin-right: 10px;"
              @close="removeGroup(groupId, project, index)"
            >
              {{ groupMap[project.project_code] &&
                groupMap[project.project_code][groupId] && 
                groupMap[project.project_code][groupId].length > 0 &&
                groupMap[project.project_code][groupId][0] &&
                groupMap[project.project_code][groupId][0].group_name
              }}
            </el-tag>
            <el-button icon="el-icon-plus" @click="openAddGroup(project.project_code)">添加研究中心</el-button>
          </div>
          <div>
            数据权限：
            <el-radio-group v-model="project.data_permission_type" class="radio-group">
              <el-radio
                label="10"
                key="10">仅自己作为负责人的数据</el-radio>
                <el-radio
                label="20"
                key="20">以上研究中心的所有数据</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleConfirm">确定</el-button>
      </span>
    </el-dialog>
    <dialog-edit-group
      :dialog-visible="showEditGroupDialog"
      :group-obj="groupMap[currentGroup]"
      :currentSelectGroupIds="currentSelectGroupIds"
      @close="showEditGroupDialog = false"
      @handleConfirm="addGroup"
    />
  </div>
</template>
<script>
import {
  apiPostAddUser,
  apiPostModifyUser,
  apiGetAllGroups,
  apiGetAllRoles,
  apiGetUserDetail,
} from '@/apis/system'
import { notifyError, notifySuccess } from '../../helpers/message'
import { buildMap } from '@/utils/util'
import DialogEditGroup from './DialogEditGroup.vue'

export default {
  components: { DialogEditGroup },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    currentUserId: {
      type: String,
      default: ''
    },
    projectList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const checkPhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
        return;
      }

      if (value.trim().length != 11) {
        callback(new Error("手机号必须是11位数字"));
        return;
      }

      if (isNaN(value)) {
        callback(new Error("手机号必须是11位数字"));
        return;
      }

      callback();
    };
    const checkEmail = (rule, value, callback) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (value && !emailRegex.test(value.trim())) {
        callback(new Error("请输入正确的邮箱"));
        return;
      }
      callback();
    };
    return {
      query: {
        display_name: '',
        phone_number: '',
        email: '',
        department: ''
      },
      rules: {
        display_name: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone_number: [
        { required: true, message: '请输入', trigger: 'blur' },
        { validator: checkPhoneNumber, trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }]
      },
      roleMap: {},
      groupMap: {},
      operateProjects: [],
      showEditGroupDialog: false,
      currentGroup: '',
      currentSelectGroupIds: '',
      loading: false
    }
  },
  computed: {
    title(){
      return this.currentUserId ? '编辑用户' : '添加用户'
    }
  },
  watch: {
    dialogVisible(val) {
      this.getOptions()
      this.init()
      if (this.currentUserId) {
        this.getUserDetail()
      } else {
        this.query = {
          display_name: '',
          phone_number: '',
          email: '',
          department: '',
          data_permission_type: ''
        }
      }
    }
  },
  methods: {
    init() {
      this.operateProjects = []
      this.projectList.map(project => {
        this.operateProjects.push({
          ...project,
          isSelected: false,
          role_ids: '',
          group_ids: '',
          groupIdList: [],
          data_permission_type: ''
        })
      })
    },
    getOptions() {
      this.getRoles()
      this.getGroups()
    },
    async getRoles() {
      const res = await apiGetAllRoles()
      if (res.code === 200) {
        this.roleMap = buildMap(res.entities, 'project_code')
      } else {
        notifyError(res.message)
      }
    },
    async getGroups() {
      const res = await apiGetAllGroups()
      if (res.code === 200) {
        this.groupMap = buildMap(res.entities, 'project_code')
        Object.keys(this.groupMap).map(key => {
          this.groupMap[key] = buildMap(this.groupMap[key], 'group_id')
        })
      } else {
        notifyError(res.message)
      }
    },
    async getUserDetail() {
      this.loading = true
      const res = await apiGetUserDetail(this.currentUserId)
      this.loading = false
      if (res.code === 200) {
        const { display_name, phone_number, email, department, details } = res.entity
        this.query.display_name = display_name
        this.query.phone_number = phone_number
        this.query.email = email
        this.query.department = department
        this.operateProjects.map(project => {
          const targetProject = details.find(item => item.project_code === project.project_code)
          if (targetProject) {
            project.isSelected = true
            project.role_ids = targetProject.role_ids
            project.group_ids = targetProject.group_ids
            project.groupIdList = targetProject.group_ids ? targetProject.group_ids.split(', ') : []
            project.data_permission_type = targetProject.data_permission_type
          }
        })
      } else {
        notifyError(res.message)
      }
    },
    closeDialog() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          const details = this.operateProjects.filter(item => item.isSelected)
          const params = {
            ...this.query,
            details,
          }
          if (this.currentUserId) {
            params.user_id = this.currentUserId
          }
          this.loading = true
          const url = this.currentUserId ? apiPostModifyUser : apiPostAddUser
          url(params).then(res => {
            if (res.code === 200) {
              notifySuccess('操作成功')
              this.closeDialog()
              this.$emit('refreshList')
            } else {
              notifyError(res.message)
            }
            this.loading = false
          })
        }
      })
    },
    openAddGroup(projectCode) {
      this.currentGroup = projectCode
      const selectItem = this.operateProjects.find(item => item.project_code === this.currentGroup)
      this.currentSelectGroupIds = selectItem.group_ids
      this.showEditGroupDialog = true
    },
    addGroup(selectGroups) {
      const selectItem = this.operateProjects.find(item => item.project_code === this.currentGroup)
      if (selectItem) {
        selectItem.group_ids = selectGroups.join(', ')
        selectItem.groupIdList = selectGroups
      }
    },
    removeGroup(groupId, project, index) {
      console.log(groupId, project)
      project.groupIdList.splice(index, 1)
      project.group_ids = project.groupIdList.join(', ')
    }
  }
}
</script>

<style lang="scss">
.genetrials-common-dialog.modal-edit-user {
  .user-form {
    width: 100%;
    .radio-group {
      margin-left: 16px;
      display: block;
    }
  }
}
</style>