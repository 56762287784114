const step_1_questionnaire = [
  {
    key: 'external_patient_code',
    label: '受试者编号',
    type: 1,
    maxlength: 20,
    tips: '2301+研究中心编号(2位)+受试者编号(3位) ,例如230101001'
  },
  {
    key: 'name',
    label: '受试者姓名缩写',
    type: 1,
    maxlength: 4,
    htmlTips: '本研究中受试者姓名缩写为受试者姓名拼音的4位首字母，录入时请录入大写字母。姓名为2个字的各取前两个字母；3个字的最后一个汉字取前两个字母，余下各取一个首字母；4个字及以上的取前四个汉字的首字母，不足4位的在中间以“-”补足。</br>例如：“鄂敏”记录为“EMIN”,“李娥”记录为“LI-E”,“张青”记录为“ZHQI”，“张青山”记录为“ZQSH”，“东方宏亮”记录为“DFHL”。'
  },
  {
    key: 'principal_id',
    label: '负责人',
    type: 4, // 从远程获取数据
    options: 'crcList',
    optionKey: 'user_id',
    optionName: 'display_name'
  },
  {
    key: 'institute_id',
    label: '所属研究中心',
    type: 4, // 从远程获取数据
    options: 'institutes',
    optionKey: 'institute_id',
    optionName: 'institute_name'
  },
  {
    key: 'date_of_icf',
    label: '签署知情同意的日期',
    type: 3,
    initalDate: '1965'
  },
  {
    key: 'file_list',
    label: '上传知情同意书',
    type: 5, // 上传文件
    desc: '文件格式限pdf, word, bmp,jpeg,jpg,png,gif,tiff,pcx,raw'
  }
]
const step_1_questionnaire_form = [
  'external_patient_code',
  'name',
  'principal_id',
  'institute_id',
  'is_signed_icf',
  'date_of_icf',
]
export {
  step_1_questionnaire,
  step_1_questionnaire_form
}